import React, {
  useCallback, useState, useEffect, Fragment
} from 'react'
import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'
import Close from '@material-ui/icons/Close'
import { mediaQuery } from 'utils/style'
import ProductHighlightModal from './ProductHighlightModal'
import ProductAccessoryModal from './ProductAccessoryModal'
import ProductPropertyModal from './ProductPropertyModal'
import ProductModal from './ProductModal'
import CustomModal from './CustomModal'

const StyledModal = styled(Modal)`
  overflow-x: hidden;
  overflow-y: auto;
`

const Backdrop = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  min-height: 100vh;
`

const Wrapper = styled.div`
  h2 {
    font-size: 50px;
    color: ${({ theme }) => theme.colors.highlight};
    font-weight: bold;
  }
  h3 {
    font-size: 30px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.highlight};
  }
  h4 {
    font-size: 25px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.highlight};
  }
  h5 {
    font-size: 25px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.color};
  }
  h6 {
    font-size: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.color};
  }
  position: unset;
  border: 1px solid black;
  background-color: white;
  border-radius: 0;
  padding: 16px;
  height: fit-content;
  display: flex;
  justify-content: center;
  z-index: 1000;
  margin: 40px;
  @media (max-width: 480px) {
    margin: 0;
  }
`

const Container = styled.div`
  width: 100%;
  position: relative;
  margin: 16px;
  ${mediaQuery(true)}
`

const CloseButton = styled(Close)`
  font-size: 32px;
  position: fixed;
  right: 56px;
  top: 56px;
  z-index: 1500;
  ${mediaQuery()({
    media: device => (device === 'mobile' ? 'top: 16px; right: 16px;' : '')
  })}
`

const stopPropagation = e => e.stopPropagation()

export const ModalContext = React.createContext()

export default function ModalProvider ({
  open,
  onRequestClose,
  product,
  slug,
  type,
  props,
  children
}) {
  const [modal, setModal] = useState({})

  const loadProductModal = useCallback((_product, _type, _props, _open) => {
    setModal({
      open: _open,
      props: { product: _product, ..._props },
      C:
        {
          highlight: ProductHighlightModal,
          accessory: ProductAccessoryModal,
          property: ProductPropertyModal
        }[_type] || ProductModal
    })
  }, [])

  const loadModal = useCallback(async (_slug, _type, _props, _open) => {
    try {
      const content = await import(`../../../.temp/${_slug}.json`)
      const prefetchData = await _props?.prefetch?.()

      if (content?.default?.component?.__typename === 'StoryblokProduct') {
        loadProductModal(content.default, _type, _props, _open)
      } else {
        setModal({
          open: _open,
          props: {
            ..._props,
            ...prefetchData,
            formData: { ..._props?.formData, ...prefetchData?.formData },
            data: content.default
          },
          C: CustomModal
        })
      }
    } catch (e) {
      console.log(_slug, e)
    }
  }, [])

  useEffect(
    () => {
      if (product) {
        loadProductModal(product, type, props, open)
      } else if (slug) {
        loadModal(slug, type, props, open)
      } else if (children) {
        setModal({ open, C: Fragment })
      }
    },
    [product, slug, type, props, open]
  )

  return (
    <ModalContext.Provider value={{ ...modal.props, onRequestClose }}>
      <StyledModal
        open={modal.open}
        hideBackdrop
        keepMounted
        onClose={onRequestClose}
      >
        <Backdrop onClick={onRequestClose}>
          <Wrapper onClick={stopPropagation}>
            <Container>
              <CloseButton onClick={onRequestClose} />
              {children}
              {modal.C && <modal.C {...modal.props} />}
            </Container>
          </Wrapper>
        </Backdrop>
      </StyledModal>
    </ModalContext.Provider>
  )
}
