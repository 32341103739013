import React, { useState, useEffect } from 'react'
import useProduct from 'hooks/useProduct'

const Context = React.createContext()

export function NavProvider ({
  children, map, lang, location, context
}) {
  const [history, setHistory] = useState([])
  useEffect(
    () => setHistory([...history, { url: location?.pathname, context }]),
    [location]
  )
  useProduct(context?._product)
  return (
    <Context.Provider
      value={{
        map,
        lang,
        history
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Context
