import React, { useState } from 'react'
import styled from 'styled-components'
import Carousel from 'components/Carousel'
import Image from 'components/Image'
import RemarkRenderer from 'components/RemarkRenderer'
import { mediaQuery } from 'utils/style'

const Item = styled.div`
  flex: 1;
  margin: 32px;
`

const Subgroup = styled.div``

const GalleryContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 64px;
  ${mediaQuery()({
    media: device => (device === 'mobile' ? 'flex-direction:column;' : ''),
  })}
`
const Accessory = styled.div`
  padding-left: 1rem;
  flex: 1;
`

const Accessories = styled.div`
  display: flex;
  flex-flow: row wrap;
  > * {
    width: 200px;
    flex: unset;
  }
`

export default function ModalSubgroup({ data }) {
  const [state, setState] = useState({})

  const subgroup = data.subgroup[0]

  const setAccessory = key => value => setState({ ...state, [key]: value })

  const childName =
    subgroup?.component?.__typename === 'StoryblokProductAccessorySubgroup'
      ? 'accessories'
      : 'properties'

  return (
    <Subgroup>
      <h5>{subgroup?.component?.title}</h5>
      <RemarkRenderer
        ast={subgroup?.component?.description?.childMarkdownRemark?.htmlAst}
      />
      {subgroup?.component?.layout === 'gallery' ? (
        <GalleryContainer>
          <Carousel
            media={device =>
              device === 'mobile' || data.fullWidth
                ? 'width: 100%;'
                : 'width: 50%; height: fit-content;'
            }
            height="fit-content"
            squared
            gallery
            infinite
            hideBackside
            onSlide={setAccessory(subgroup.id)}
          >
            {subgroup?.component?.[childName]?.map(accessory => (
              <Image
                title={accessory.component.imageTitle}
                src={accessory.component.image}
                alt={accessory.component.imageAlt}
                description={accessory.component.imageDescription}
              />
            ))}
          </Carousel>
          <Accessory>
            <h5>
              {
                subgroup?.component?.[childName]?.[state[subgroup.id] || 0]
                  ?.component?.title
              }
            </h5>
            <RemarkRenderer
              ast={
                subgroup?.component?.[childName]?.[state[subgroup.id] || 0]
                  ?.component?.description?.childMarkdownRemark?.htmlAst
              }
            />
          </Accessory>
        </GalleryContainer>
      ) : (
        <Accessories>
          {subgroup?.component?.[childName]?.map(accessory => (
            <Item>
              <Image
                title={accessory.component.imageTitle}
                src={accessory.component.image}
                alt={accessory.component.imageAlt}
                description={accessory.component.imageDescription}
              />
              <p>{accessory.component.imageTitle}</p>
            </Item>
          ))}
        </Accessories>
      )}
    </Subgroup>
  )
}
