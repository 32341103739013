import React, { useContext, useReducer } from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import Modal from 'components/Modal'
import NavContext from 'components/NavProvider'

const isModal = slug => /modals\/(.*)/.test(slug)
const isProduct = slug => /products\/(.*)/.test(slug)

const isUrl = (str) => {
  try {
    // eslint-disable-next-line no-new
    new URL(str)
    return true
  } catch (_) {
    return false
  }
}

function StoryblokModalLink (props) {
  const {
    link,
    children,
    productModalType,
    productModalGroup,
    modalProps,
    style,
    onClick,
    ...rest
  } = props

  const [open, toggle] = useReducer(state => {
    if(!state && onClick && typeof onClick === 'function') onClick()
    return !state
  }, false)

  return (
    <>
      <Modal
        onRequestClose={toggle}
        open={open}
        slug={link.story.url
          .split('/')
          .filter(x => x !== '')
          .map(x => x.replace(/^.._..$/, l => l.replace('_', '-')))
          .join('/')}
        type={productModalType}
        props={{ group: productModalGroup?.[0]?.id, ...modalProps }}
      />
      <a
        tabIndex={0}
        role="button"
        onKeyPress={toggle}
        onClick={toggle}
        style={{ ...style, cursor: 'pointer' }}
        {...rest}
      >
        {children}
      </a>
    </>
  )
}

function StoryblokLink (props) {
  const {
    link,
    children,
    productModalType,
    productModalGroup,
    modalProps,
    style,
    gtmEvent,
    ...rest
  } = props
  if(gtmEvent) rest.onClick= () => dataLayer.push({event: gtmEvent})
  const { map: navMap, lang } = useContext(NavContext) || {}
  const url = link?.story?.url || link?.url
  if (!url) {
    return (
      <a style={style} {...rest}>
        {children}
      </a>
    )
  }

  if (url.indexOf('#') === 0) {
    return (
      <a
        tabIndex={0}
        role="button"
        style={{ ...style, cursor: 'pointer' }}
        href={url}
        {...rest}
      >
        {children}
      </a>
    )
  }

  const modal = isModal(url) || isProduct(url)
  const isExternal = link.linktype === 'url'

  if (modal) {
    return <StoryblokModalLink {...props} />
  }
  if (isExternal) {
    if (url.startsWith('_self')) {
      if(navMap?.[url]?.slug?.startsWith('http')) {
        return (
          <a
            href={navMap?.[url]?.slug}
            title={navMap?.[url]?.title}
            style={style}
            {...rest}
          >
            {children}
          </a>
        )
      }
      return (
        <Link
          to={navMap?.[url]?.slug}
          title={navMap?.[url]?.title}
          style={style}
          {...rest}
        >
          {children}
        </Link>
      )
    }
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...style, cursor: 'pointer' }}
        {...rest}
      >
        {children}
      </a>
    )
  }

  const sanitized = url
    .split('/')
    .filter(x => x !== '')
    .map(x => x.replace(/^.._..$/, l => l.replace('_', '-')))
    .join('/')

  if (navMap?.[sanitized]?.slug === '_self') {
    return (
      <a
        title={navMap?.[sanitized]?.title}
        style={{ ...style, cursor: 'pointer' }}
        {...rest}
      >
        {children}
      </a>
    )
  }

  return (
    <Link
      to={
        navMap?.[sanitized]?.slug
        || navMap?.[`${lang}/${sanitized}`]?.slug
        || sanitized
      }
      title={
        navMap?.[sanitized]?.title || navMap?.[`${lang}/${sanitized}`]?.title
      }
      {...rest}
    >
      {children}
    </Link>
  )
}

StoryblokLink.propTypes = {}

export default StoryblokLink
