import React from 'react'
import Image from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import { mediaQuery } from 'utils/style'

const Img = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  ${({ flex, row, col }) =>
    (flex ? `flex:1;${col ? 'max-width:100%;' : 'max-height:100%;'}` : '')}
  ${props =>
    (props.relative
      ? `
    max-width:100%;
    max-height:100%;
  `
      : '')}
  :hover {
    ${({ hover }) => hover || ''}
  }
  ${mediaQuery()}

  img ,
  > .gatsby-image-wrapper {
    ${({ absolute }) =>
    (absolute
      ? `position: absolute !important;
        top: 0;
        left: 0;`
      : '')}
    width: 100%;
    height: 100%;
    ${props =>
    props.relative
      && `
      max-width:100%;
      max-height:100%;
    `}
    object-fit: contain !important;
    ${props => props.imageStyle}
  }
`
const BGImg = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  ${mediaQuery()}

  img,
  > .gatsby-image-wrapper {
    object-fit: cover;
    width: 100%;
    height: 100%;
    ${props => props.imageStyle}
  }
`
export default function SBImage ({
  src,
  background,
  children,
  alt,
  title,
  description,
  objectPosition,
  fallback,
  ...props
}) {
  const Wrapper = background ? BGImg : Img

  return (
    <Wrapper {...props}>
      {src?.localFile ? (
        src.localFile.childImageSharp ? (
          <Image
            {...src.localFile.childImageSharp}
            alt={alt}
            title={title}
            description={description}
            objectFit={background ? 'cover' : 'contain'}
            objectPosition={objectPosition}
          />
        ) : (
          <img
            src={src.localFile.publicURL}
            alt={alt}
            title={title}
            description={description}
            objectFit={background ? 'cover' : 'contain'}
          />
        )
      ) : (
        typeof src === 'string'
        && src && (
          // && (fallback ? (
          //   <FallbackImage
          //     key={src}
          //     src={src}
          //     fallback={fallback}
          //     alt={alt}
          //     title={title}
          //     description={description}
          //     background={background}
          //     objectPosition={objectPosition}
          //   />
          // ) : (
          <img
            src={src}
            alt={alt}
            title={title}
            description={description}
            objectFit={background ? 'cover' : 'contain'}
            objectPosition={objectPosition}
          />
        )
      )}
      {children}
    </Wrapper>
  )
}
