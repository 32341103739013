import React from 'react'
import styled, { withTheme } from 'styled-components'
import Image from 'components/Image'

const Container = styled.div`
  position: relative;
  ${({ squared }) => (squared ? 'height: 0; padding-bottom: 100%;' : '')}
`

function StoryblokImage ({ data, theme }) {
  return (
    <Container squared={data.squared}>
      <Image
        style={{
          backgroundColor: theme.colors[data.backgroundColor]?.color,
          ...(data.squared && { height: 0, paddingBottom: '100%' })
        }}
        imageStyle={{ padding: data.padding ? '5px' : 0 }}
        absolute={data.squared}
        src={data.image}
        alt={data.alt}
        title={data.title}
        description={data.description}
      />
    </Container>
  )
}

export default withTheme(StoryblokImage)
