import React from 'react'
import RehypeReact from 'rehype-react'
import StoryblokLink from 'components/Link'
import {
  path, map, evolve, addIndex, unless, not
} from 'ramda'

function A (props) {
  const { className, href, ...rest } = props
  if (className === 'remark-autolink') return React.createElement('a', props)
  return React.createElement(StoryblokLink, {
    className,
    ...rest,
    link: {
      url: href,
      story: { url: href },
      linktype: href.startsWith('/') ? 'internal_stories' : 'url'
    }
  })
}

function Table (props) {
  const headers = map(
    path(['props', 'children', 0]),
    path(['children', 0, 'props', 'children', 0, 'props', 'children'], props)
  )
  const addDataLabels = (c, idx) =>
    (c.type === 'td'
      ? { ...c, props: { ...c.props, 'data-label': headers[idx] } }
      : evolve({
        props: { children: unless(not, addIndex(map)(addDataLabels)) }
      })(c))

  const newProps = {
    ...props,
    className: ['remark-table'].concat(props.className).join(' '),
    children: [props.children[0], addDataLabels(props.children[1], 1)]
  }
  return React.createElement('table', newProps)
}

export const Rehype = new RehypeReact({
  createElement: React.createElement,
  components: {
    a: A,
    table: Table
  }
})

export const ModalRehype = new RehypeReact({
  createElement: React.createElement,
  components: {
    a: A,
    table: Table,
    h1: 'h2',
    h2: 'h3',
    h3: 'h4',
    h4: 'h5',
    h5: 'h6'
  }
})

export const renderModalAst = ModalRehype.Compiler

export default Rehype.Compiler
