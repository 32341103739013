import * as Sentry from '@sentry/browser'

const isProd = process.env.NODE_ENV === 'production'

Sentry.init({
  dsn: 'https://2eb573f9e96642e0ac2096576baf500e@sentry.io/1802426',
  environment: isProd ? 'production' : 'development',
  release: `normstahl-website@${window.__GIT_REV}`,
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage'
  ],
  blacklistUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
  ],
  beforeBreadcrumb (breadcrumb, hint) {
    // skip succeeding firestore rt requests
    if (
      breadcrumb.data?.url?.match(
        /firestore\.googleapis\.com\/google\.firestore\.v1\.Firestore\/Listen\/channel/i
      )
      && (breadcrumb.data?.status_code === 200
        || breadcrumb.data?.status_code === 0)
    ) {
      return null
    }

    return breadcrumb
  }
})
