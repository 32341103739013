import { useEffect, useState, useRef } from 'react'

export default function useProduct (product) {
  const [state, setState] = useState(null)
  const ref = useRef(null)

  const getProduct = async (slug) => {
    try {
      const p = await import(`../../.temp/${slug}.json`)
      setState(p.default)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(
    () => {
      if (product?.full_slug && product.full_slug !== ref.current) {
        ref.current = product.full_slug
        getProduct(product.full_slug)
      }
    },
    [product]
  )

  return state || product
}
