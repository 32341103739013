import React, {
  useRef,
  useEffect,
  useState,
  useReducer,
  useLayoutEffect
} from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import Modal from 'components/Modal'
import { mediaQuery } from 'utils/style'
import clamp from './clamp.js'

/**
 * multuline text-overflow: ellipsis
 */

export const Container = styled.div`
  position: relative;
  a {
    color: ${({ theme }) => theme.colors[theme.primary].color};
  }
`

const ClampContainer = styled.div`
  position: absolute;
  width: 100%;
  visibility: hidden;
`
const Clamped = styled.div`
  :before {
    content: '';
    position: absolute;
    background: rgba(0,0,0,0.2);
    opacity: 0;
    border-radius: 100%;
    width: 25%;
    height: 25%;
    top: 37.5%;
    left: 37.5%;
    animation: ripple 20s ease-out infinite;
  }
  position: relative;
  max-height: 100%;
  width: 100%;
  > div > div {
    display: flex;
    justify-content: center;
  }
`

export default function Clamper (props) {
  const {
    children, style, expandable, media = () => {}, ...clampProps
  } = props
  if (!clampProps.clamp) {
    return (
      <Container media={media} style={style}>
        {children}
      </Container>
    )
  }
  const ref = useRef()
  const [expanded, expand] = useReducer(state => !state, false)
  const [state, setState] = useState()
  const doClamp = () => {
    if (ref.current) {
      setState(clamp(ref.current, clampProps))
    }
  }
  useEffect(() => {
    window.addEventListener('resize', doClamp)
    doClamp()
    return () => {
      window.removeEventListener('resize', doClamp)
    }
  }, [])
  useLayoutEffect(
    () => {
      if (ref.current) {
        setState(clamp(ref.current, clampProps))
      }
    },
    [children]
  )
  return (
    <>
      {state?.clamped && (
        <Modal open={expanded} onRequestClose={expand}>
          {children}
        </Modal>
      )}
      <Container
        media={media}
        style={style}
        onClick={() => state?.clamped && expand()}
      >
        <ClampContainer ref={ref}>{children}</ClampContainer>
        {state?.clamped ? (
          <Clamped
            style={style}
            dangerouslySetInnerHTML={{
              __html: state.clamped
            }}
          />
        ) : (
          children
        )}
      </Container>
    </>
  )
}
