import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import Cookies from 'js-cookie'
import Button from 'components/Button'
import RemarkRenderer from 'components/RemarkRenderer'
import { mediaQuery } from 'utils/style'

const Background = styled.div`
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 800;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const Slope = styled.div`
  height: 0;
  width: 100%;
  border-bottom: 6.54vw solid white;
  border-left: 100vw solid transparent;
  filter: drop-shadow(0 -10px 5px);
  ${({ theme }) => (theme.slopes ? '' : 'display: none;')}
`

const Container = styled.div`
  ${({ theme }) => (theme.slopes ? '' : 'filter: drop-shadow(0 -10px 5px);')}
  background-color: rgba(255,255,255,0.9);
  box-shadow: 0px -5px 10px rgba(0,0,0,0.35);
  display: flex;
  align-items: center;
  justify-content: center;
`
const Disclaimer = styled.div`
  display: flex;
  ${mediaQuery(true)({
    media: device => (device === 'mobile' ? 'flex-direction:column;' : '')
  })}
  > :first-child {
    margin: 5px;
    flex: 1;
  }
  > :last-child {
    margin: 5px;
    height: 2em;
    align-self: flex-end;
  }
`

export default function CookieDisclaimer ({ text, buttonLabel }) {
  const [accepted, setAccepted] = useState(true)
  const accept = useCallback(() => {
    Cookies.set('accepted-cookies', true)
    setAccepted(true)
  }, [])
  useEffect(() => setAccepted(Cookies.get('accepted-cookies')), [])
  if (accepted) return null
  return (
    <Background>
      <Container>
        <Disclaimer>
          <RemarkRenderer ast={text?.childMarkdownRemark?.htmlAst} />
          <Button onClick={accept}>{buttonLabel}</Button>
        </Disclaimer>
      </Container>
    </Background>
  )
}
