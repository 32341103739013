import { useContext } from 'react'
import ProductContext from 'components/ProductContext'

export default function useProductContext (product, key) {
  const context = useContext(ProductContext) || {}

  return {
    selected: context[product.id]?.[key],
    select: context.select(product.id, key)
  }
}
