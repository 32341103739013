/**
 *
 * RemarkRenderer
 *
 */

import React, { useContext } from 'react'
import { ModalContext } from 'components/Modal'
import renderAst, { renderModalAst } from './renderAst'
import Clamper from './Clamper'

function RemarkRenderer ({
  trimSingle, ast, flexFill, clamp, style, ...props
}) {
  const modalContext = useContext(ModalContext)
  if (!ast || ast.children.length === 0) return <div />
  const render = modalContext != null ? renderModalAst : renderAst
  return (
    <Clamper
      useNativeClamp={false}
      clamp={clamp}
      splitOnChars={[' ', '-']}
      style={{
        ...(flexFill && {
          flex: 1,
          minHeight: 0,
          display: 'flex',
          ...flexFill
        }),
        ...style
      }}
      {...props}
    >
      {render(
        trimSingle && ast.children.length === 1
          ? {
            ...ast,
            children: [{ ...ast.children[0], tagName: 'span' }]
          }
          : ast
      )}
    </Clamper>
  )
}

export default RemarkRenderer
export { Container } from './Clamper'
