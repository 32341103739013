import React, {
  useContext, useState, useReducer, useEffect
} from 'react'
import styled from 'styled-components'
import Menu from '@material-ui/icons/Menu'
import Link from 'components/Link'
import Image from 'components/Image'
import NavContext from 'components/NavProvider'
import Close from '@material-ui/icons/Close'
import { mediaQuery } from 'utils/style'
import LanguageSelect from './LanguageSelect'

const noPropagation = (e) => {
  e.stopPropagation()
}

const ArrowRight = styled.div`
  margin-left: 1rem;
  height: 0;
  width: 0;
  border-left: 0.6rem solid;
  border-bottom: 0.4rem solid transparent;
  border-top: 0.4rem solid transparent;
  ${mediaQuery()({
    media: device =>
      (device === 'tablet'
        ? `position: absolute;
      top: 6px;
      right: 7px;`
        : '')
  })}
`

const logoQuery = device =>
  (device === 'tablet'
    ? 'height: 68px; width: 120px;'
    : 'mobile'
      ? 'z-index: 999;'
      : '')

const Logo = styled.div`
  top: 0;
  height: 134px;
  width: 240px;
  z-index: 1001;
  align-self: flex-start;
  position: absolute;
  ${mediaQuery()}
`
const Backdrop = styled.div`
  top: 0;
  left: 0;
  z-index: 900;
  && {
    width: 100vw;
    max-width: unset;
    height: 100vh;
  }
  position: fixed;
  display: none;
  background-color: rgba(0, 0, 0, 0.75);
  ${mediaQuery()({
    media: device => (device === 'tablet' ? 'display:initial;' : '')
  })}
`

const containerQuery = device =>
  (device === 'max'
    ? 'width: calc(50vw + 720px);'
    : device === 'desktop'
      ? `width: calc(50vw + 540px);
       max-width: 100%;`
      : device === 'tablet'
        ? 'max-width: 100%; width: calc(50vw + 360px);'
        : 'width: 100%;')

const Container = styled.div`
  margin-bottom: 0;
  align-self: flex-start;
  display: flex;
  justify-content: flex-end;
  && {
    margin-bottom: 0;
    ${mediaQuery()}
  }
`

const barQuery = device =>
  (device === 'max'
    ? ''
    : device === 'desktop'
      ? `min-width:1080px;
       max-width: unset;
       width: fit-content;`
      : device === 'tablet'
        ? `min-width: unset;
       margin-bottom: -80px;`
        : 'min-width: unset; margin-bottom: -80px;justify-content: flex-end;')

const Bar = styled.div`
  background-color: white;
  display: flex;
  height: 80px;
  align-items: flex-end;
  && {
    ${mediaQuery(true)};
    margin-bottom: 0;
  }
`

const navQuery = device =>
  (device === 'tablet'
    ? `
      overflow: auto;
      min-width: 50vw;
      flex-direction: column;
      position: absolute;
      right: 16px;
      top: -100px;
      max-height: calc(61.8vh + 100px);
      padding: 0;
      box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 5px;`
    : device === 'mobile'
      ? 'width: 80vw; top: calc(20px - 38.2vh); max-height: calc(100vh - 40px);'
      : '')

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: flex-end;
  padding-left: 244px;
  background-color: white;
  @media (max-width: 1080px) {
    display: ${({ active }) => (active ? 'flex' : 'none')};
  }
  ${mediaQuery()}
`

const navWrapperQuery = device =>
  (device === 'tablet'
    ? `
   position: fixed;
   right: -16px;
   top: 38.2%;
   height: 64px;
   width: 64px;
   border-radius: 64px;
   z-index: 999;
   box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 5px;
   > svg {
     display: unset;
     position: absolute;
     left: 3px;
     top: 8px;
     height: 48px;
     width: 48px;
   }`
    : '')

const MobileNavWrapper = styled.div`
  width: 100%;
  background-color: white;
  > svg {
    display: none;
  }
  ${mediaQuery()}
`

const CloseButton = styled(Close)`
  && {
    font-size: 32px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1500;
    ${mediaQuery()({
    media: device =>
      (device === 'tablet' || device === 'mobile'
        ? 'display: initial;'
        : 'display: none;')
  })}
  }
`

const panelQuery = device =>
  (device === 'tablet'
    ? `border:none;
      background-clip:unset;
      margin:0;
      position:unset;
      padding-left:16px;
      width:100%;
      max-height:unset;
      height:unset;
      writing-mode:lr-tb;
      writing-mode:horizontal-tb;
      display: flex;
      flex-direction: column;`
    : '')

const Panel = styled.div`
  border: 20px solid transparent;
  background-clip: content-box;
  margin: -20px;
  font-weight: normal;
  align-items: stretch;
  display: inline-flex;
  flex-direction: row;
  writing-mode: tb-lr;
  writing-mode: vertical-lr;
  max-height: ${({ row }) => `calc(100vh - ${3.5 * row}rem - 190px)`};
  height: ${({ length }) => `${3.5 * length}rem`};
  box-sizing: content-box;
  flex-wrap: wrap;
  background-color: white;
  position:absolute;
  z-index: 950;
  ${mediaQuery()({ media: panelQuery })}
  color: ${({ theme }) => theme.colors.navigation.color};
  :hover {
    color: ${({ theme }) => theme.colors.navigation.inactive};
  }
`

const NavItem = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  writing-mode: lr-tb;
  writing-mode: horizontal-tb;
  padding: 1rem;
  :hover {
    color: ${({ theme }) => theme.colors.navigation.highlight};
    > ${Panel} {
      display: inline-flex;
    }
  }
  > ${Panel} {
    display: none;
    left: 100%;
    top: 0;
  }
  ${mediaQuery()({
    media: device =>
      (device === 'tablet'
        ? `flex-direction: column;
          width: 100%;
          align-items: flex-start;
          padding: 5px;
          > a {
              margin-right: 30px;
          }
          :hover {
            > ${Panel} {
              display: none;
            }
          }`
        : '')
  })}
  @media (max-width: 1080px) {
    ${({ active, theme }) =>
    (active
      ? `
        > ${ArrowRight} { transform: rotateZ(90deg); }
        > ${Panel} {
              display: flex !important;
            }`
      : '')}
  }
`

const NavItemLabel = styled.div``

const NavRoot = styled.div`
  ${({ theme }) => (theme.boldNavRoot ? 'font-weight: bold;' : '')}
  height: fit-content;
  cursor: default;
  white-space: nowrap;
  padding: 0 1rem;
  margin: 0.5rem 0;
  position: relative;
  background-color: white;
  color: ${({ theme }) => theme.colors.navigation.color};
  :hover {
    > ${Panel} {
      display: flex;
    }
    color: ${({ theme }) => theme.colors.navigation.highlight};
  }
  > ${ArrowRight} {
    display: none;
    top: 20px;
    right: 7px;
  }
  ${mediaQuery()({
    media: device =>
      (device === 'tablet'
        ? `margin: 0;
          padding: 16px 0 16px 16px;
          font-weight: normal;
          border-right: 0;
          border-bottom: 1px solid black;
          width: calc(100% - 16px);
          :last-child {
            border-bottom: none;
          }
          > ${ArrowRight} { display:unset; }
          > a {
              margin-right: 30px;
          }
          > svg { display: none; }
          :hover {
            > ${Panel} {
              display: none;
            }
          }`
        : '')
  })}
  :last-child {
    > ${Panel} {
      left: auto;
      right: 0;
      > ${NavItem} {
        justify-content: flex-end;
      }
    }
  }
  ${({ primary }) =>
    primary && 'font-weight: bold; border-right: 1px solid black;'}
  >svg {
    height: 20px;
    margin: 0 0.5rem -0.2em 0;
    color: ${({ theme }) => theme.colors.navigation?.color};
  }
  > ${Panel} {
    display: ${({ active }) => (active ? 'flex' : 'none')};
    background-clip: content-box;
    margin-top: 0;
    border-top: 1rem solid transparent;
    ${mediaQuery()({
    media: device =>
      (device === 'tablet' ? 'margin:0;border-top: none;' : '')
  })}
    top: 1.5rem;
    left: 0;
  }
  ${({ active }) =>
    (active
      ? `> ${Panel} {
              display: flex !important;
          }
        > ${ArrowRight} { transform: rotateZ(90deg); }`
      : '')}
`

function NavigationPanel ({ items, row = 0 }) {
  const [active, setActive] = useState({})
  const onTouchStart = id => (e) => {
    e.stopPropagation()
    e.preventDefault()
    setActive({ ...active, [id]: !active[id] })
  }
  return (
    <Panel row={row} length={items.length}>
      {items.map((item, idx) => (
        <NavItem
          key={item.id}
          active={active[item.id]}
          onTouchEnd={onTouchStart(item.id)}
          /* {...!item.link && { onTouchEnd: onTouchStart(item.id) }} */
        >
          <Link
            link={item.link}
            {...item.link && { onTouchEnd: noPropagation }}
          >
            <NavItemLabel>{item.label}</NavItemLabel>
          </Link>
          {item.items?.filter(i => !i.hidden).length ? (
            <>
              <ArrowRight onClick={onTouchStart(item.id)} />
              <NavigationPanel row={row + idx} items={item.items.filter(i => !i.hidden)} />
            </>
          ) : (
            []
          )}
        </NavItem>
      ))}
    </Panel>
  )
}

export default function Navigation ({ data }) {
  const [active, setActive] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {}
  )
  const { history } = useContext(NavContext)
  const onTouchStart = (id, propagation) => (e) => {
    if (!propagation) {
      e.stopPropagation()
      e.preventDefault()
    }
    setActive({ [id]: !active[id] })
  }
  useEffect(
    () => {
      setActive({ root: false })
    },
    [history]
  )
  return (
    <>
      {active.root && <Backdrop onClick={onTouchStart('root')} />}
      <Container media={containerQuery}>
        <Bar media={barQuery}>
          <Logo className="logo" media={logoQuery}>
            <Link link={data.link}>
              <Image
                src={data.logo}
                style={{
                  filter: 'drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5))'
                }}
              />
            </Link>
          </Logo>

          <MobileNavWrapper
            media={navWrapperQuery}
            onTouchMove={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            onClick={onTouchStart('root', true)}
          >
            <Menu onClick={onTouchStart('root')} />
            <Nav media={navQuery} active={active.root}>
              <CloseButton onClick={onTouchStart('root')} />
              {data.items
                .filter(item => !item.hidden)
                .map((item, idx) => (
                  <NavRoot
                    primary={!idx}
                    key={item.id}
                    active={active[item.id]}
                    onTouchEnd={onTouchStart(item.id)}
                    /* {...!item.link && { onTouchEnd: onTouchStart(item.id) }} */
                  >
                    {!idx && <Menu />}
                    <Link
                      link={item.link}
                      {...item.link && { onTouchEnd: noPropagation }}
                    >
                      {item.label}
                    </Link>
                    {item.items?.filter(item => !item.hidden).length > 0 ? (
                      <>
                        <ArrowRight onClick={onTouchStart(item.id)} />
                        <NavigationPanel
                          items={item.items.filter(item => !item.hidden)}
                        />
                      </>
                    ) : (
                      []
                    )}
                  </NavRoot>
                ))}
            </Nav>
          </MobileNavWrapper>
        </Bar>
      </Container>
      {data.showWelcomeOverlay && (
        <LanguageSelect
          roofline={data.welcomeRoofline}
          headline={data.welcomeHeadline}
          subline={data.welcomeSubline}
          navigation={data.welcomeNavigation}
          locator={data.locatorLink}
          internationalLabel={data.welcomeInternationalButtonLabel}
          locatorLabel={data.welcomeLocatorButtonLabel}
        />
      )}
    </>
  )
}
