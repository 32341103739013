import React, { useState } from 'react'
import styled from 'styled-components'
import Carousel from 'components/Carousel'
import Image from 'components/Image'
import RemarkRenderer from 'components/RemarkRenderer'
import Subgroup from 'components/StoryblokModalSubgroup'
import HR from 'components/StoryblokSeparator'

const Groups = styled.div`
  display: flex;
  height: 10rem;
  width: 100%;
`

const Item = styled.div`
  flex: 1;
  margin: 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`
const Container = styled.div`
  width: 100%;
  > * {
    margin-bottom: 32px;
  }
`

export default function ProductAccessoryModal ({ product, ...props }) {
  const [state, setState] = useState(props)
  const subgroups = product?.component?.properties

  const groups = subgroups?.reduce(
    (acc, subgroup) =>
      (acc.find(g => g.id === subgroup?.component?.group?.[0]?.id)
        ? acc
        : [...acc, subgroup?.component?.group[0]]),
    []
  )

  const group = groups.find(g => g.id === state.group) || groups[0]

  const setGroup = g => () => setState({ ...state, group: g.id })

  return (
    <Container>
      <div>
        <h1>{product?.component?.propertiesTitle}</h1>
        <div className="subline">{product?.component?.propertiesSubtitle}</div>
      </div>
      <RemarkRenderer
        ast={
          product?.component?.propertiesDescription?.childMarkdownRemark
            ?.htmlAst
        }
      />
      <Groups>
        {groups.map(g => (
          <Item onClick={setGroup(g)}>
            <Image src={g.component.image} />
            <p>{g.component.title}</p>
          </Item>
        ))}
      </Groups>
      <HR />
      {subgroups
        .filter(sg => sg.component?.group?.[0]?.id === group.id)
        .map(sg => (
          <Subgroup key={sg.id} data={{ subgroup: [sg] }} />
        ))}
    </Container>
  )
}
