import React, { useState } from 'react'
import styled from 'styled-components'
import RemarkRenderer from 'components/RemarkRenderer'
import Link from 'components/Link'
import Image from 'components/Image'
import { baseColors } from 'utils/theme'
import { mediaQuery } from 'utils/style'
import StoryblokComponents from 'components/StoryblokComponents'
import CookieDisclaimer from './CookieDisclaimer'

const Container = styled.div`
  && {
    width: 100%;
    max-width:unset;
    margin-bottom: 0;
  }
  display:flex;
  flex-direction:column;
  justify-content:flex-end;
  align-items:center;
  flex: 1;
  background-color: ${baseColors.lightGray};
  overflow:hidden;
}`

const NavItem = styled.div`
  font-size: 12px;
  padding-left: 2em;
  position: relative;
  ::before {
    content: '•';
    position: absolute;
    left: 0;
    top: 0;
    width: 2em;
    text-align: center;
  }
  :first-child {
    padding: 0;
    ::before {
      content: none;
    }
  }
`
const NavItems = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`

const Links = styled.div`
  padding-top: 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`
const Squares = styled.div`
  position: relative;
  flex: 1;
  height: 0;
`

const SquaresWrapper = styled.div`
  padding-left: 16px;
  top: 0;
  position: relative;
  width: 100%;
  transform: translateY(-40%);
`

const Slope = styled.div`
  border-bottom: 6.54vw solid ${baseColors.lightGray};
  border-left: 100vw solid ${baseColors.white};
  z-index: 101;
  position: relative;
  flex: 1;
  ${({ theme }) => (theme.slopes ? '' : 'display: none;')}
`
const Content = styled.div`
  padding: 16px 0;
  ${mediaQuery(true)}
`

const SocialMediaLink = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 16px;
`

export default function Footer ({ data }) {
  if (!data?.links) return null
  return (
      <Container>
        <Slope />
        <Content>
          <RemarkRenderer ast={data.address?.childMarkdownRemark?.htmlAst} />
          <Links>
            {data.socialMediaLinks?.map(item => (
              <Link key={item.id} link={item.link}>
                <SocialMediaLink>
                  <Image src={item.icon} />
                </SocialMediaLink>
              </Link>
            ))}
            <NavItems>
              {data.links.map(item => (
                <NavItem key={item.id}>
                  <Link link={item.link}>{item.label}</Link>
                </NavItem>
              ))}
            </NavItems>
            <Squares>
              <SquaresWrapper>
                <Image flex col src={data.image} />
              </SquaresWrapper>
            </Squares>
          </Links>
        </Content>
      {/*<CookieDisclaimer
          text={data.cookieDisclaimerText}
          buttonLabel={data.cookieButtonLabel}
        />*/}

        <StoryblokComponents components={data.popups} />
      </Container>
  )
}
