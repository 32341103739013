export const color = (propName, color = 'color') => props =>
  props?.theme?.colors?.[props[propName]]?.[color]
export const image = propName => props => {
  const prop = props?.[propName]

  return typeof prop === 'string'
    ? prop
    : prop?.localFile?.childImageSharp?.original?.src
}

export const mediaQuery = (setWidth = false) => ({ media = () => `` }) => `
  ${setWidth ? `width: 1440px;` : ``}
  ${media('max')}

  @media (max-width: 1640px) {
    ${setWidth ? `max-width: 1080px; width:100%;` : ``}
    ${media('desktop')}
  }
  @media (max-width: 1080px) {
    ${setWidth ? `max-width: 720px; width: 100%;` : ``}
    ${media('tablet')}
  }
  @media (max-width: 480px) {
    ${setWidth ? `width: 100%;` : ``}
    ${media('mobile')}
  }
`
