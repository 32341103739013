import React from 'react'
import { withTheme } from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Image from 'components/Image'
import StoryblokImage from 'components/StoryblokImage'
import RemarkRenderer from 'components/RemarkRenderer'

function productModal ({ product, theme }) {
  return (
    <>
      <Image
        style={{ height: '61.8vh' }}
        src={product?.component?.image}
        alt={product?.component?.imageAlt}
        title={product?.component?.imageTitle}
      />
      <h4 style={{ margin: 0 }}>{product?.component?.Roofline}</h4>
      <h1 style={{ margin: 0 }}>{product?.component?.description2Title}</h1>
      <h4 style={{ marginBottom: 32 }}>{product?.component?.subTitle}</h4>
      <RemarkRenderer
        ast={product?.component?.description2?.childMarkdownRemark?.htmlAst}
        style={{ paddingBottom: 10 }}
      />
      <Grid container spacing={1}>
        {product?.component?.productSpecsTitle && (
          <>
            <Grid item xs={1}>
              <StoryblokImage
                data={{
                  image: product?.component?.productSpecsImage,
                  imageAlt: product?.component?.productSpecsImageAlt,
                  imageTitle: product?.component?.productSpecsImageTitle,
                  imageDescription:
                    product?.component?.productSpecsImageDescription,
                  backgroundColor: theme.primary,
                  squared: true,
                  padding: true
                }}
              />
            </Grid>
            <Grid item xs={11}>
              <h5>{product?.component?.productSpecsTitle}</h5>
              <RemarkRenderer
                ast={
                  product?.component?.productSpecsText?.childMarkdownRemark
                    ?.htmlAst
                }
              />
            </Grid>
          </>
        )}
        {product?.component?.uspTitle1 && (
          <>
            <Grid item xs={1}>
              <StoryblokImage
                data={{
                  image: product?.component?.uspImage1,
                  imageAlt: product?.component?.uspImage1Alt,
                  imageTitle: product?.component?.uspImage1Title,
                  imageDescription: product?.component?.uspImage1Description,
                  backgroundColor: theme.secondary,
                  squared: true,
                  padding: true
                }}
              />
            </Grid>
            <Grid item xs={11}>
              <h5>{product?.component?.uspTitle1}</h5>
              <RemarkRenderer
                ast={product?.component?.uspText1?.childMarkdownRemark?.htmlAst}
              />
            </Grid>
          </>
        )}
        {product?.component?.uspTitle2 && (
          <>
            <Grid item xs={1}>
              <StoryblokImage
                data={{
                  image: product?.component?.uspImage2,
                  imageAlt: product?.component?.uspImage2Alt,
                  imageTitle: product?.component?.uspImage2Title,
                  imageDescription: product?.component?.uspImage2Description,
                  backgroundColor: theme.primary,
                  squared: true,
                  padding: true
                }}
              />
            </Grid>
            <Grid item xs={11}>
              <h5>{product?.component?.uspTitle2}</h5>
              <RemarkRenderer
                ast={product?.component?.uspText2?.childMarkdownRemark?.htmlAst}
              />
            </Grid>
          </>
        )}
        {product?.component?.uspTitle3 && (
          <>
            <Grid item xs={1}>
              <StoryblokImage
                data={{
                  image: product?.component?.uspImage3,
                  imageAlt: product?.component?.uspImage3Alt,
                  imageTitle: product?.component?.uspImage3Title,
                  imageDescription: product?.component?.uspImage3Description,
                  backgroundColor: theme.secondary,
                  squared: true,
                  padding: true
                }}
              />
            </Grid>
            <Grid item xs={11}>
              <h5>{product?.component?.uspTitle3}</h5>
              <RemarkRenderer
                ast={product?.component?.uspText3?.childMarkdownRemark?.htmlAst}
              />
            </Grid>
          </>
        )}
        {product?.component?.uspTitle4 && (
          <>
            <Grid item xs={1}>
              <StoryblokImage
                data={{
                  image: product?.component?.uspImage4,
                  imageAlt: product?.component?.uspImage4Alt,
                  imageTitle: product?.component?.uspImage4Title,
                  imageDescription: product?.component?.uspImage4Description,
                  backgroundColor: theme.primary,
                  squared: true,
                  padding: true
                }}
              />
            </Grid>
            <Grid item xs={11}>
              <h5>{product?.component?.uspTitle4}</h5>
              <RemarkRenderer
                ast={product?.component?.uspText4?.childMarkdownRemark?.htmlAst}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}
export default withTheme(productModal)
