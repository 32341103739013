/**
 *
 * StoryblokComponent
 *
 */

import React, { Component } from 'react'
import { pascal } from 'change-case'
import FallbackComponent from './FallbackComponent'

class StoryblokComponent extends Component {
  state = {
    component: null,
    isLoading: true,
    error: null
  }

  async componentDidMount () {
    const { component: data, onLoad, onError } = this.props

    const type = data._editable
      ? `Storyblok${pascal(data.component)}`
      : data.__typename

    try {
      let editable
      let component
      if (data._editable) {
        try {
          ({
            default: component
          } = await import(/* webpackChunkName: "storyblok-component-" */
          /* webpackMode: "lazy" */
            `components/${type}/editable.js`))
        } catch (e) {
          ({
            default: component
          } = await import(/* webpackChunkName: "storyblok-component-" */
          /* webpackMode: "lazy" */
            `components/${type}`))
        }
        ({ default: editable } = await import('storyblok-react'))
      } else {
        ({
          default: component
        } = await import(/* webpackChunkName: "storyblok-component-" */
        /* webpackMode: "lazy" */
          `components/${type}`))
      }
      this.setState({
        type,
        component,
        isLoading: false,
        editable
      })
      if (onLoad) onLoad()
    } catch (error) {
      this.setState({ type, error, isLoading: false })
      if (onError) onError(error)
    }
  }

  componentDidCatch (error) {
    this.setState({ error })
  }

  render () {
    const { component, ...props } = this.props
    const {
      type, error, component: C, isLoading, editable: E
    } = this.state
    if (isLoading) return null
    if (E) {
      if (error) {
        console.log({ component, error })
        return (
          <E content={component}>
            <FallbackComponent type={type} error={error} />
          </E>
        )
      }
      return (
        <E content={component}>
          <C data={component} {...props} />
        </E>
      )
    }
    if (error) {
      console.log({ component, error, C })
      return <FallbackComponent type={type} error={error} />
    }
    return <C data={component} {...props} />
  }
}

StoryblokComponent.propTypes = {}

export default React.memo(StoryblokComponent)
