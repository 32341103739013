import React from 'react'
import styled from 'styled-components'
import Link from 'components/Link'
import { mediaQuery } from 'utils/style'

const Container = styled.div`
  margin-top: 64px;
  && {
    margin-bottom: 0;
  }
  > a :nth-child(n + 2) {
    :before {
      content: '>';
      padding: 0 5px;
    }
  }
`
const AbsoluteContainer = styled.div`
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 590px;
  display: flex;
  flex-wrap: wrap;
  ${mediaQuery()({
    media: device =>
      device === 'tablet'
        ? 'top: 290px;'
        : device === 'mobile'
        ? 'display: none;'
        : '',
  })}
  z-index: 600;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  > a {
    white-space: pre;
    color: ${({ theme }) => theme.colors.black.highlight};
    :nth-child(n + 2) {
      :before {
        color: white;
        content: '>';
        padding: 0 5px;
      }
    }
    :last-child {
      color: ${({ theme }) => theme.colors.black.contrast};
    }
  }
`

const getNextItems = ({ items = [] }) =>
  !Array.isArray(items)
    ? []
    : items?.reduce(
        (acc, item) => [
          ...acc,
          ...(item.slug === '' ? getNextItems(item) : item && [item]),
        ],
        []
      )

function Breadcrumbs({ slug, navigation, firstComponent }) {
  const C = firstComponent === 'carousel' ? AbsoluteContainer : Container
  return (
    <C>
      {
        slug
          .split('/')
          .filter(Boolean)
          .reduce(
            (acc, value) => {
              const item = acc.items?.find(i => i?.slug === value)
              return {
                items: item ? getNextItems(item) : acc.items,
                result: [
                  ...acc.result,
                  ...(!!item?.link
                    ? [<Link link={item?.link}>{item?.label}</Link>]
                    : []),
                ],
              }
            },
            { items: getNextItems(navigation), result: [] }
          ).result
      }
    </C>
  )
}

export default React.memo(Breadcrumbs)
