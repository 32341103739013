import styled from 'styled-components'
import { mediaQuery } from 'utils/style'

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  > div {
    flex-basis: auto;
    padding: 0 5px;
    margin-bottom: 64px;
    ${mediaQuery(true)}
  }
`
