import React, {
  useState, useCallback, useEffect, useContext
} from 'react'
import styled from 'styled-components'
import { navigate } from '@reach/router'
import {
  equals, takeLastWhile, complement, memoizeWith, identity
} from 'ramda'
import Cookies from 'js-cookie'
import Button from 'components/Button'
import Link from 'components/Link'
import NavContext from 'components/NavProvider'
import { mediaQuery } from 'utils/style'

const Slope = styled.div`
  height: 0;
  border-top: 6.54vw solid white;
  border-right: 100vw solid transparent;
  filter: drop-shadow(0 10px 5px);
  width: 100%;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 90px;
  ${mediaQuery()({ media: device => (device === 'tablet' ? 'top: 0;' : '') })}
  z-index: 900;
  && {
    padding: 0;
    width: 100%;
    max-width: unset;
  }
`

const Background = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 64px 0;
  width: 100%;
`

const Container = styled.div`
  ${mediaQuery(true)}
  padding: 5px;
`
const Navigation = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 32px;
`
const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  :last-child {
    margin-right: 0;
  }
  > a {
    display: flex;
    align-items: center;
    ${Button} {
      margin: 8px 0;
      width: 100%;
    }
  }
`

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 9px solid ${({ theme }) => theme.colors[theme.primary].color};
  margin: 8px 6px 8px 0;
`

const Circle = styled.div`
  width: 0;
  height: 0;
  border-radius: 4px;
  border: 4px solid ${({ theme }) => theme.colors[theme.primary].color};
  margin-left: 12px;
  margin-right: 6px;
`

export default function CookieDisclaimer ({
  roofline,
  headline,
  subline,
  navigation,
  locator,
  internationalLabel,
  locatorLabel
}) {
  const [accepted, setAccepted] = useState(true)
  const { map: navMap, lang } = useContext(NavContext) || {}
  const accept = useCallback(
    memoizeWith(identity, value => () => {
      Cookies.set('languageSelected', value)
      setAccepted(value)
    }),
    []
  )

  useEffect(() => {
    const cookieLang = Cookies.get('languageSelected')
    setAccepted(cookieLang)

    if (
      lang === 'default'
      && cookieLang
      && cookieLang !== 'default'
      && cookieLang !== true
      && navMap
    ) {
      // navigate(navMap[`_self_${cookieLang}`]?.slug)
    }
  }, [])

  useEffect(
    () => {
      if (lang) {
        Cookies.set('languageSelected', lang)
      }
    },
    [lang]
  )

  if (accepted || lang !== 'default') return null

  const itemsPerCol = Math.ceil(
    navigation?.reduce((acc, { items }) => acc + 1 + (items?.length || 0), 0)
      / 3
  )
  const items = navigation?.reduce(
    ({ acc, result }, item) =>
      (acc >= itemsPerCol
        ? { acc: (item.items?.length || 0) + 1, result: [...result, [item]] }
        : {
          acc: acc + (item.items?.length || 0) + 1,
          result: [
            ...result.slice(0, -1),
            [...result[result.length - 1], item]
          ]
        }),
    { acc: 0, result: [[]] }
  ).result

  return (
    <Overlay>
      <Background>
        <Container>
          <div className="roofline">{roofline}</div>
          <h1>{headline}</h1>
          <div className="subline">{subline}</div>
          <Navigation>
            {items?.map(col => (
              <Col>
                {col.map(item => (
                  <>
                    <Link
                      onClick={accept(
                        takeLastWhile(
                          complement(equals('_')),
                          item.link.story?.url || item.link.url
                        )
                      )}
                      link={item.link}
                    >
                      <Triangle />
                      {item.label}
                    </Link>
                    {item.items?.map(subItem => (
                      <Link
                        onClick={accept(
                          takeLastWhile(
                            complement(equals('_')),
                            subItem.link.story?.url || item.link.url
                          )
                        )}
                        className="inner"
                        link={subItem.link}
                      >
                        <Circle />
                        {subItem.label}
                      </Link>
                    ))}
                  </>
                ))}
              </Col>
            ))}
            <Col style={{ justifyContent: 'flex-end', flex: 'unset' }}>
              <Link
                onClick={accept('default')}
                link={{
                  linktype: 'url',
                  url: '_self_default'
                }}
              >
                <Button>{internationalLabel}</Button>
              </Link>
              <Link onClick={accept(true)} link={locator}>
                <Button>{locatorLabel}</Button>
              </Link>
            </Col>
          </Navigation>
        </Container>
      </Background>
      <Slope />
    </Overlay>
  )
}
