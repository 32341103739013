const baseFontSize = 15

export const baseColors = {
  green: '#b1c800',
  lightGreen: '#dbe49a',

  yellow: '#ffcc00',
  lightYellow: '#ffe595',

  red: '#e53434',
  lightRed: '#f4a68f',

  white: '#ffffff',
  black: '#000000',
  darkGray: '#404040',
  gray: '#808080',
  lightGray: '#e6e6e6',

  blue: '#009ee0',
  lightBlue: '#84d0f0',

  marine: '#004d8b',
  lightMarine: '#80a6c5'
}

const colors = color => ({
  options: {
    selected: baseColors.blue
  },
  blue: {
    button: baseColors.lightBlue,
    color: baseColors.blue,
    highlight: baseColors.white,
    contrast: baseColors.white
  },
  lightBlue: {
    button: baseColors.blue,
    color: baseColors.lightBlue,
    highlight: baseColors.black,
    contrast: baseColors.black
  },
  green: {
    button: baseColors.blue,
    color: baseColors.green,
    highlight: baseColors.white,
    contrast: baseColors.white
  },
  lightGreen: {
    color: baseColors.lightGreen,
    highlight: baseColors.black,
    contrast: baseColors.black
  },
  yellow: {
    button: baseColors.blue,
    color: baseColors.yellow,
    highlight: baseColors.black,
    contrast: baseColors.black
  },
  lightYellow: {
    button: baseColors.blue,
    color: baseColors.lightYellow,
    highlight: baseColors.black,
    contrast: baseColors.black
  },
  red: {
    button: baseColors.blue,
    color: baseColors.red,
    highlight: baseColors.white,
    contrast: baseColors.white
  },
  lightRed: {
    button: baseColors.blue,
    color: baseColors.lightRed,
    highlight: baseColors.black,
    contrast: baseColors.black
  },
  black: {
    button: baseColors.blue,
    color: baseColors.darkGray,
    highlight:
      color === 'gray'
        ? baseColors.white
        : color === 'marine'
          ? baseColors.lightMarine
          : baseColors[color],
    contrast: baseColors.white
  },
  gray: {
    button: baseColors.blue,
    color: baseColors.gray,
    highlight: baseColors[color],
    contrast: baseColors.white
  },
  marine: {
    button: baseColors.blue,
    color: baseColors.marine,
    highlight: baseColors.white,
    contrast: baseColors.white
  },
  lightMarine: {
    button: baseColors.blue,
    color: baseColors.lightMarine,
    highlight: baseColors.black,
    contrast: baseColors.black
  },
  navigation: {
    color: baseColors.black,
    highlight: baseColors.blue,
    inactive: baseColors.gray
  },
  highlight: baseColors[color],
  color: baseColors.black,
  contrast: baseColors.white
})

export const breakpoints = {
  mobile: '40rem',
  tablet: '64rem',
  desktop: '80rem'
}

const theme = (color, crawford) => ({
  baseFontSize,
  breakpoints: [breakpoints.mobile, breakpoints.tablet, breakpoints.desktop],
  maxWidth: '100%', // breakpoints.desktop,
  space: [0, 4, 8, 16, 32, 64, 128, 256].map(s => `${s / baseFontSize}rem`),
  primary: color,
  secondary: `light${color.replace(/^\w/, c => c.toUpperCase())}`,
  tertiary: color === 'blue' ? 'green' : 'blue',
  colors: colors(color),
  slopes: !crawford,
  boldNavRoot: !!crawford
})

export default theme
