/**
 *
 * StoryblokComponents
 *
 */

import React, { Component } from 'react'
import StoryblokComponent from 'components/StoryblokComponent'

class StoryblokComponents extends Component {
  componentsLoaded = {}

  static defaultProps = {
    components: [],
  }

  onStoryblokComponentLoad = id => {
    const { components, onLoaded } = this.props
    this.componentsLoaded[id] = true
    const allComponentsLoaded = components.every(
      blok => this.componentsLoaded[blok.id]
    )
    if (allComponentsLoaded && onLoaded) onLoaded()
  }

  render() {
    const { components } = this.props
    return components?.map((component, idx) => (
      <StoryblokComponent
        key={component.id}
        component={component}
        previous={components[idx - 1]?.__typename}
        next={components[idx + 1]?.__typename}
        onLoad={() => this.onStoryblokComponentLoad(component.id)}
        onError={() => this.onStoryblokComponentLoad(component.id)}
      />
    ))
  }
}

export default StoryblokComponents
