import React from 'react'

export default function FallbackComponent (props) {
  const { type, error } = props
  if (process.env.NODE_ENV === 'production') return null
  return (
    <div>
      Failed to load component {type}
      <pre>{JSON.stringify(error, null, 2)}</pre>
    </div>
  )
}
